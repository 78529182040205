
//const APIURL = 'http://localhost:3000/api/'; // Local
const APIURL = 'https://api.vanguardvision.ai/api/'; // AWS BE
//const APIURL = 'https://devback2.vanguardtech.solutions/api/'; // Production
//const APIURL = 'https://devback3.vanguardtech.solutions/api/'; // Development

//const APIURLVision = "https://visionbackend.vanguardtech.solutions";
const APIURLVision = "https://us0j5ho11l.execute-api.us-east-1.amazonaws.com/dev-vision";

//const APIURLPerms = 'https://tob9omblt1.execute-api.us-east-1.amazonaws.com/Prod/permission-group/';

const CDNURL = "https://d19m9fthzcikee.cloudfront.net/";

const VERSION = "V - 1.1.23";

const DDMMYYYY = (date, characterDivider) => {
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();

    return [(dd > 9 ? '' : '0') + dd, (mm > 9 ? '' : '0') + mm, date.getFullYear()].join(characterDivider);
}

const DDMMYYYYstring = (date) => {
    const dateIntoISO = new Date(date).toISOString().substr(0, 10).replace(/-/gi, "/");

    return dateIntoISO.split('/').reverse().join('/')
}

const COdate = () => {
    const date = new Date()
    const offset = '-5'

    const utc = date.getTime() + (date.getTimezoneOffset() * 60000)

    const exactDatetime = new Date(utc + (3600000 * offset))

    const exactDate = exactDatetime.getFullYear() + '-' + (exactDatetime.getMonth() + 1 > 9 ? '' : '0') + (exactDatetime.getMonth() + 1) + '-' + (exactDatetime.getDate() > 9 ? '' : '0') + (exactDatetime.getDate())
    const exactTime = (exactDatetime.getHours() > 9 ? '' : '0') + (exactDatetime.getHours()) + ':' + (exactDatetime.getMinutes() > 9 ? '' : '0') + (exactDatetime.getMinutes()) + ':' + (exactDatetime.getSeconds() > 9 ? '' : '0') + (exactDatetime.getSeconds())

    return exactDate + 'T' + exactTime + '.000Z'
}

const getAge = (birthdate) => {
    birthdate = birthdate.split("-");
    let dob = new Date(birthdate[2], birthdate[1], birthdate[0])
    let diff_ms = Date.now() - dob.getTime();
    let age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
}

const sortArray = (array, key, order) => {
    return array.sort(function(a, b) {
        var x = a[key]; var y = b[key];
        if (order == "desc") {
            return ((x < y) ? 1 : ((x > y) ? -1 : 0));
        } else {
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        }
    });
}

const validateSurveyStatus = (lastSurveyCreationDate) => {
    let lastSurveyDate = new Date(lastSurveyCreationDate)
    let lastAvailableSurveyDate = new Date(lastSurveyDate.setHours(lastSurveyDate.getHours() + 17)).toISOString();

    if (COdate() >= lastSurveyCreationDate && COdate() <= lastAvailableSurveyDate) {
        return true;
    } else {
        return false;
    }
}

export default { DDMMYYYY, DDMMYYYYstring, COdate, getAge, sortArray, APIURL, APIURLVision, CDNURL, VERSION, validateSurveyStatus }
