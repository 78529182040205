<template>
  <v-container fluid style="height: 100%;" class="pb-0">
    <v-dialog
      v-model="askSecureRegistryModal"
      transition="dialog-bottom-transition"
      max-width="600"
      persistent
    >
      <v-card>
        <v-card-text>
          <div class="text-h6 pt-3 text-center">Se enviará a gestión rápida {{ quickRegisterEvents.length }} persona{{ quickRegisterEvents.length == 1 ? '' : 's' }}</div>
          <div class="text-h6 text-center">¿Desea proceder con el envio?</div>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            text
            color="success"
            @click="enviarOneID"
          >Si, proceder</v-btn>
          <v-btn
            text
            color="error"
            @click="askSecureRegistryModal = !askSecureRegistryModal"
          >Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div v-if="knownModal">
      <v-dialog v-model="knownModal" content-class="modalVs" max-width="1000px">
        <v-card>
          <v-card-title>
            <span class="text-h6">Información Usuario</span>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12" md="12" class="text-center">
                <p class="font-weight-light pb-2 secondary--text" style="font-size: 1.15rem">IMG. REGISTRO</p>
                <div class="avatarAcces2 d-flex justify-center">
                  <v-img
                    style="max-height: 150px; max-width: 250px"
                    :src="'https://devback2.vanguardtech.solutions/api/entry/registry-pic/' + selectedKnownUser.register_image"
                    lazy-src="../../assets/loading.png"
                    contain
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="black"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="text-center">Tipo Documento: {{ selectedKnownUser.document_type }} </div>
                <div class="text-center">Documento: {{ selectedKnownUser.document_number }}</div>
                <div class="text-center">Nombre: {{  selectedKnownUser.first_name + (selectedKnownUser.second_name ? selectedKnownUser.second_name : "") + " " + selectedKnownUser.first_last_name + " " + (selectedKnownUser.second_last_name ? selectedKnownUser.second_last_name : "") }}</div>
                <div class="text-center">Grupo de Permisos: {{ getPG(selectedKnownUser.company_person.pin) }}</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-layout child-flex>
                <v-data-table
                  :headers="headersKnown"
                  :items="selectedKnownUserEvents"
                  :items-per-page="10"
                  :header-props="{
                    mobile: true,
                  }"
                  :footer-props="{
                    'items-per-page-options': [10],
                  }"
                  fixed-header
                  no-data-text="No hay eventos que mostrar"
                >

                  <template v-slot:[`item.ImgAccess`]="{ item }">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-avatar
                          size="42"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-img
                            :src="getVisionidUrlPic(item.img_id, 'face_')"
                            lazy-src="../../assets/loading.png"
                            @error="'../../assets/loading.png'"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="black"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>
                      </template>
                      <v-row class="pa-0">
                        <v-col cols="4" class="py-0 d-flex justify-center align-center">
                          <v-img
                            :src="getVisionidUrlPic(item.img_id, 'frame_')"
                            max-width="100"
                            lazy-src="../../assets/loading.png"
                            @error="'../../assets/loading.png'"
                          >
                          <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="black"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-col>
                        <v-col cols="4" class="py-0 d-flex justify-center align-center">
                          <v-img
                            :src="getVisionidUrlPic(item.img_id, 'body_')"
                            max-width="80"
                            lazy-src="../../assets/loading.png"
                            @error="'../../assets/loading.png'"
                          >
                          <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="black"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-col>
                        <v-col cols="4" class="py-0 d-flex justify-center align-center">
                          <v-img
                            :src="getVisionidUrlPic(item.img_id, 'face_')"
                            max-width="60"
                            lazy-src="../../assets/loading.png"
                            @error="'../../assets/loading.png'"
                          >
                          <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="black"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-col>
                      </v-row>
                      <!-- <v-img
                        :src="getVisionidUrlPic(item.img_id, 'face_')"
                        width="200"
                        lazy-src="../../assets/loading.png"
                        @error="'../../assets/loading.png'"
                      >
                       <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="black"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img> -->
                      
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.asset`]="{ item }">
                    <div>
                      {{ getZoneName(item.asset) }}
                    </div>
                  </template>
                  <template v-slot:[`item.slave`]="{ item }">
                    <div>
                      {{ getCamName(item.slave) }}
                    </div>
                  </template>
                  <template v-slot:[`item.type_event`]="{ item }">
                    <v-chip
                      class="small-chip ma-0"
                      :color="getColorEvent(item.type_event)"
                      text-color="white"
                      small
                    >
                      {{ item.type_event === 'granted' ? 'Permitido' : item.type_event === 'denied' ? 'Denegado' : 'Lista Negra' }}
                    </v-chip>
                  </template>
                  <template v-slot:[`item.created_time`]="{ item }" >
                    {{
                      item.created_time.substring(0, 10)
                    }} - {{
                      item.created_time.substring(11, 16)
                    }}
                  </template>
                </v-data-table>
              </v-layout>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <div v-if="unknownModal">
      <v-dialog v-model="unknownModal" content-class="modalVs" max-width="1000px">
        <v-card>
          <v-card-title>
            <span class="text-h6">Información Desconocido</span>
          </v-card-title>

          <v-card-text>
            <!-- <v-row>
              <v-col v-if="entrySelected && entrySelected.access_type !== 'unrecognized' && entrySelected.access_type !== 'denied_by_company'" cols="12" md="6" class="text-center">
                <p class="font-weight-light pb-2 secondary--text" style="font-size: 1.15rem">IMG. REGISTRO</p>
                <div class="avatarAcces2">
                  <v-img
                    style="max-height: 250px; max-width: 600px"
                    :src="PSRegistryImg"
                    lazy-src="../assets/loading.png"
                    @error="imgErrorHandler"
                    contain
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="black"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>
              </v-col>
              <v-col cols="12" :md="entrySelected && entrySelected.access_type !== 'unrecognized' && entrySelected.access_type !== 'denied_by_company' ? 6 : 12" class="text-center">
                <p v-if="lunchModule" class="font-weight-light pb-2 secondary--text" style="font-size: 1.15rem">IMG. ÚLTIMA ENTREGA</p>
                <p v-if="!lunchModule" class="font-weight-light pb-2 secondary--text" style="font-size: 1.15rem">IMG. ÚLTIMO ACCESO</p>
                <div class="avatarAcces2">
                  <v-img
                    style="max-height: 250px; max-width: 600px"
                    :src="PSDailyImg"
                    lazy-src="../assets/loading.png"
                    @error="'../assets/loading.png'"
                    contain
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="black"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>
              </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="12">
                <v-layout child-flex>
                <v-data-table
                  :headers="headersUnknown"
                  :items="selectedUnknownUserEvents"
                  :items-per-page="10"
                  :header-props="{
                    mobile: true,
                  }"
                  :footer-props="{
                    'items-per-page-options': [10],
                  }"
                  fixed-header
                  no-data-text="No hay eventos que mostrar"
                >

                  <template v-slot:[`item.ImgAccess`]="{ item }">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-avatar
                          size="42"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-img
                            :src="getVisionidUrlPic(item.img_id, 'face_')"
                            lazy-src="../../assets/loading.png"
                            @error="'../../assets/loading.png'"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="black"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>
                      </template>
                      <v-row class="pa-0">
                        <v-col cols="4" class="py-0 d-flex justify-center align-center">
                          <v-img
                            :src="getVisionidUrlPic(item.img_id, 'frame_')"
                            max-width="100"
                            lazy-src="../../assets/loading.png"
                            @error="'../../assets/loading.png'"
                          >
                          <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="black"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-col>
                        <v-col cols="4" class="py-0 d-flex justify-center align-center">
                          <v-img
                            :src="getVisionidUrlPic(item.img_id, 'body_')"
                            max-width="80"
                            lazy-src="../../assets/loading.png"
                            @error="'../../assets/loading.png'"
                          >
                          <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="black"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-col>
                        <v-col cols="4" class="py-0 d-flex justify-center align-center">
                          <v-img
                            :src="getVisionidUrlPic(item.img_id, 'face_')"
                            max-width="60"
                            lazy-src="../../assets/loading.png"
                            @error="'../../assets/loading.png'"
                          >
                          <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="black"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-col>
                      </v-row>                      
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.asset`]="{ item }">
                    <div>
                      {{ getZoneName(item.asset) }}
                    </div>
                  </template>
                  <template v-slot:[`item.slave`]="{ item }">
                    <div>
                      {{ getCamName(item.slave) }}
                    </div>
                  </template>
                  <template v-slot:[`item.type_event`]>
                    <v-chip
                      class="small-chip ma-0"
                      color="error"
                      text-color="white"
                      small
                    >
                      DESCONOCIDO
                    </v-chip>
                  </template>
                  <template v-slot:[`item.created_time`]="{ item }" >
                    {{
                      item.created_time.substring(0, 10)
                    }} - {{
                      item.created_time.substring(11, 16)
                    }}
                  </template>
                </v-data-table>
              </v-layout>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <v-row>
      <v-col cols="12" class="py-0">
        <div :style="activateFastRegistry === true ? 'display: grid; grid-template-columns: 70% 20% 10%;' : 'display: grid; grid-template-columns: 70% 30%;'">
          <h4 class="text-left ml-2">VISION ID</h4>
          <div v-if="recognizedEvents.length > 0 || notRecognizedEvents.length > 0" class="d-flex justify-center align-center">
            <p class="pt-1">{{ activateFastRegistry === false ? 'Activar ' : 'Desactivar ' }} CheckIn</p>
            <v-checkbox
              v-model="activateFastRegistry"
              class="my-0"
              color="secondary"
              hide-details
              dense
              @click="$event = !$event"
            ></v-checkbox>
          </div>
          <div class="d-flex justify-center align-center">
            <v-btn v-if="activateFastRegistry === true" :disabled="quickRegisterEvents.length === 0" @click="askSecureRegistryModal = !askSecureRegistryModal" color="secondary" x-small>Enviar CheckIn</v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6" class="pt-0" style="height: 60hv !important">
        <v-tabs height="30" style="width: 83%">
          <v-tab v-for="(camera, index) in camerasList" :key="index" class="px-1" @click="changeStream(camera)">
            {{ camera.name }}
          </v-tab>
        </v-tabs>
        <div v-if="loadingStreaming">
          <p class="text-center">Cargando</p>
          <v-progress-linear
            color="white"
            buffer-value="0"
            stream
          ></v-progress-linear>
        </div>
        <div v-if="streamingUp" style="background-color: transparent !important; width: 100%; height: 100%;">
          <iframe
            :src="urlVideo"
            style="width: 100% !important; height: 100% !important"
            frameborder="0"
          ></iframe>
        </div>
        <div v-if="!streamingUp && !loadingStreaming">
          <v-row>
            <v-col>
              <div class="text-center">
                <v-icon style="font-size: xxx-large">mdi-video-off-outline</v-icon>
                <h4>No es posible establecer conexion con el feed de video.</h4>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>

      <v-col cols="12" md="6" class="py-0">
        <v-card style="height: 100% !important">
          <div class="pa-2 text-center">RECONOCIDOS</div>

          <v-row v-if="recognizedEvents.length > 0" class="mx-0 d-flex align-stretch" style="min-height: 370px; max-height: 390px; overflow: hidden; overflow-y: scroll;overflow-x: hidden;">
            <v-col class="px-1 d-flex justify-center" cols="12" md="2" v-for="(person, i) in recognizedEvents" :key="i">
              <v-card @click="getKnownData(person.events)" rounded="lg" class="d-flex flex-column justify-between" width="90%" max-height="230px" style="background-color: #3C465D !important;" :class="'border-' + person.events[0].type_event">
                <div class="pa-0 ma-0 d-flex justify-center">
                  <v-img :src="getVisionidUrlPic(person.events[0].img_id, 'face_')" max-width="60px" class="text-center">
                    <!-- <div class="d-flex align-end" style="width: 100%; height: 100%;">
                      <v-img :src="getVisionidUrlPic(person.events[0].img_id, 'frame_')" max-width="60px" contain
                        lazy-src="https://cdn.wallpapersafari.com/38/20/PB9auk.png">
                      </v-img>
                    </div> -->
                  </v-img>
                </div>
                <div class="text-center" style="font-size: x-small">
                  <p class="my-1">{{ person.events[0].name }}</p>
                  <p class="mb-1">F: {{ person.events[0].created_time.substring(0, 10) }}</p>
                  <p class="mb-1">H: {{ person.events[0].created_time.substring(11, 19) }}</p>
                  <p class="mb-1">C: {{ getCamName(person.events[0].slave) ? getCamName(person.events[0].slave) : "N/A" }}</p>
                  <p class="mb-1">L: {{ getZoneName(person.events[0].asset) ? getZoneName(person.events[0].asset) : "N/A"}}</p>
                  <!-- <p class="mb-1">{{ person.events[0].document_number }}</p>
                  <p class="mb-1">C: {{ getCamName(person.events[0].slave) ? getCamName(person.events[0].slave) : "N/A" }}</p>
                  <p class="mb-1">L: {{ getZoneName(person.events[0].asset) ? getZoneName(person.events[0].asset) : "N/A"}}</p>
                  <p class="mb-1">Detecciones Previas: {{ person.events.length - 1 }}</p> -->
                  <div v-if="activateFastRegistry" class="d-flex justify-center align-center my-1">
                    <p class="pt-1">CheckIn rápido</p>
                    <v-checkbox
                      class="my-0"
                      color="white"
                      hide-details
                      dense
                      @change="quickRegister(person.events[0], $event)"
                    ></v-checkbox>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col>
              <div class="text-center">
                <h5>No hay eventos que mostrar</h5>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="pt-1 pb-0">
        <v-card>
          <div class="pa-2 text-center">NO RECONOCIDOS</div>
          <v-row class="mx-0" v-if="notRecognizedEvents.length > 0" style="max-height: 160px; overflow: hidden; overflow-y: scroll;overflow-x: hidden;">

            <v-col class="px-1 d-flex justify-center" cols="12" md="1" sm="6" v-for="(unknown, i) in notRecognizedEvents" :key="i">
              <v-card rounded="lg" class="d-flex flex-column justify-between" @click="selectedUnknownUserEvents = unknown.events ; knownModal = false ; unknownModal = true" width="80%" style="border-bottom: 5px solid red; background-color: #3C465D !important;">
                <div class="pa-0 ma-0 d-flex justify-center">
                  <v-img :src="getVisionidUrlPic(unknown.events[0].img_id, 'face_')" max-width="60px" class="text-center">
                    <!-- <div class="d-flex align-end" style="width: 100%; height: 100%;">
                      <v-img :src="getVisionidUrlPic(unknown.events[0].img_id, 'frame_')" max-width="60px" contain
                        lazy-src="https://cdn.wallpapersafari.com/38/20/PB9auk.png">
                      </v-img>
                    </div> -->
                  </v-img>
                </div>
                <div class="text-center" style="font-size: x-small">
                  <p class="mt-2 mb-1">F: {{ unknown.events[0].created_time.substring(0, 10) }}</p>
                  <p class="mt-2 mb-1">H: {{ unknown.events[0].created_time.substring(11, 19) }}</p>
                  <!-- <p class="mb-1">C: {{ getCamName(unknown.events[0].slave) ? getCamName(unknown.events[0].slave) : "N/A" }}</p>
                  <p class="mb-1">L: {{ getZoneName(unknown.events[0].asset) ? getZoneName(unknown.events[0].asset) : "N/A"}}</p>
                  <p class="mb-1">Visitas Previas: {{ unknown.events.length - 1 }}</p> -->
                  <div v-if="activateFastRegistry" class="d-flex justify-center align-center my-1">
                    <p class="pt-1">CheckIn rápido</p>
                    <v-checkbox
                      class="my-0"
                      color="white"
                      hide-details
                      dense
                      @change="quickRegister(unknown.events[0], $event)"
                    ></v-checkbox>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col>
              <div class="text-center">
                <h5>No hay eventos que mostrar</h5>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import globals from "../../globals";
import axios from "axios";
import auth from "../../services/auth";
//import backlog from "../services/logs";

export default {
  name: "GeneralDashboard",
  components: {
  },
  data() {
    return {
      urlVideo: "http://10.10.1.164:5000/video_feed",
      camerasList: [],
      recognizedEvents: [],
      notRecognizedEvents: [],
      devices: [],
      blList: [],
      permissionGroupsList: [],
      sedes: [],
      streamingUp: null,
      loadingStreaming: false,
      quickRegisterEvents: [],
      registry: [],
      activateFastRegistry: false,
      askSecureRegistryModal: false,
      knownModal: false,
      unknownModal: false,
      headersKnown: [
        { text: "Imgs.", sortable: false, align: "center", value: "ImgAccess" },/* 
        { text: "N. Doc", sortable: false, align: "center", value: "document_number" },
        { text: "Nombre", sortable: false, align: "center", value: "name" }, */
        { text: "Zona", sortable: false, align: "center", value: "asset" },
        { text: "Cam", sortable: false, align: "center", value: "slave" },
        { text: "Tipo", sortable: true, align: "center", value: "type_event" },
        { text: "Fecha - Hora", sortable: true, align: "center", value: "created_time" },
      ],
      headersUnknown: [
        { text: "Imgs.", sortable: false, align: "center", value: "ImgAccess" },
        { text: "Zona", sortable: false, align: "center", value: "asset" },
        { text: "Cam", sortable: false, align: "center", value: "slave" },
        { text: "Tipo", sortable: false, align: "center", value: "type_event" },
        { text: "Fecha - Hora", sortable: true, align: "center", value: "created_time" },
      ],
      selectedKnownUserEvents: [],
      selectedUnknownUserEvents: [],
      selectedKnownUser: null
    };
  },
  methods: {
    getColorEvent(type) {
      if (type === "granted") {
        return "green";
      } else {
        if (type === "denied") {
          return "orange";
        } else {
          return "black";
        }
      }
    },

    getPG(id) {
      let pg = this.permissionGroupsList.find(x => x.uuid === id)
      return pg ? pg.name : "Sin Permisos Asignados"
    },

    getCamName(id) {
      let cam = this.camerasList.find(x => x.uuid === id)
      return cam ? cam.name : ""
    },

    getZoneName(id) {
      let zone = this.sedes.find(x => x.uuid === id)
      return zone ? zone.name : ""
    },

    enviarOneID() {
      const esta = this.quickRegisterEvents;
      let dataToSend = []

      esta.map((event) => {
        if (event.type_event === 'granted' || event.type_event === 'denied' || event.type_event === 'black_list') {
          let esto = this.registry.find(x => x.uuid === event.person_id);
          console.log(esto)
          dataToSend.push({
            "user_id": esto.uuid,
            "names": [esto.first_name, esto.second_name ? esto.second_name : ""],
            "last_names": [esto.first_last_name, esto.second_last_name ? esto.second_last_name : ""],
            //"images_ID": "",
            "register_image": esto.register_image, 
            "doc_type": esto.document_type,
            "doc_number": esto.document_number,
            "reference_image": event.img_id,
            //"expiration_date": "",
            "email": esto.email,
            "phone": esto.phone,
            type: "known"
          })
        } else {
          if (event.type_event === 'unknown_by_company') {
            dataToSend.push({
              "user_id": event.person_id,
              "names": ["", ""],
              "last_names": ["", ""],
              "images_ID": "",
              "selfie_key": "",
              "doc_type": "",
              "doc_number": "",
              "expiration_date": "",
              "email": "",
              "phone": "",
              "reference_image": event.img_id,
              "type": "unknown_by_company"
            })
          } else {
            dataToSend.push({
              "user_id": event.person_id,
              "names": ["", ""],
              "last_names": ["", ""],
              "images_ID": "",
              "selfie_key": "",
              "doc_type": "",
              "doc_number": "",
              "expiration_date": "",
              "email": "",
              "phone": "",
              "reference_image": event.img_id,
              "type": "unknown"
            })
          }
        }
      });
      console.log(dataToSend)

      let url = "https://main.d1y08sa9dwqg8n.amplifyapp.com/Login?guests=" + JSON.stringify(dataToSend);

      window.open(url, "_blank");
    },

    quickRegister(person, event) {
      let findIndex = this.quickRegisterEvents.findIndex(x => x.person_id === person.person_id)

      if (event) {
        if (this.quickRegisterEvents.length < 6) {
          if (findIndex === -1) {
            this.quickRegisterEvents.push(person)
          }
        }
      } else {
        if (findIndex !== -1) {
          this.quickRegisterEvents.splice(findIndex, 1)
        }
      }
    },

    getKnownData(events) {
      this.selectedKnownUser = null
      this.selectedKnownUserEvents = events;
      this.selectedKnownUser = this.registry.find(x => x.uuid === events[0].person_id);
      this.knownModal = true;
      this.unknownModal = false;
    },

    changeStream(camera) {
      axios({
        method: "POST",
        baseURL: "http://10.10.1.164:5000/receive_message",
        data: {
          message: "cam:" + camera.id,
        },
      }).then((data) => {
        console.log(data)
      }).catch((err) => {
        console.log(err)
      });
    },

    getVisionidUrlPic(img_id, type) {
      return type = globals.APIURL + "entry/get-pic-visionid/" + type + img_id + ".jpg";
    },

    getPremissionGroups() {
      axios({
        method: "GET",
        baseURL: globals.APIURL + "permission-group/get-pg/" + auth.get_asset_id(),
      }).then((data) => {
        if (data.data.length > 0) {
          for (let i = 0; i < data.data.length; i++) {
            const element = data.data[i];

            if (element.type_group == 'black_list') {
              this.blList.push(element)
              data.data.splice(i, 1)
            }
          }
          this.permissionGroupsList = data.data
        }
      }).catch((err) => {
        console.log(err)
      });
    },

    getRegistry() {
      axios
      .post(globals.APIURL + "registry/all", {
        company_id: auth.getUserEnterpriseID()
      })
      .then((res) => {
        if (res.data && res.data.length > 0) {
          console.log(res.data)
          this.registry = res.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
    },

    getEntries() {
      axios({
        method: "POST",
        url: globals.APIURL + "entry/grouped-entries-visionid",
        data: {
          start_date: /* globals.COdate().substring(0, 10) +  */"2024-08-01T00:00:00.000Z",
          finish_date: /* globals.COdate().substring(0, 10) +  */"2024-08-07T23:59:59.000Z",
          limit: 20000,
          type: "known",
          ids: auth.getGZ().zones
        },
      })
      .then((res) => {
        if (res.data && res.data.length > 0 && res.data != 'No documents were found') {
          const groupedData = res.data.reduce((acc, item) => {
            const personId = item.person_id;

            // Find the group for the current person_id or create a new one
            let group = acc.find(group => group.person_id === personId);
            if (!group) {
              group = { person_id: personId, events: [] };
              acc.push(group);
            }

            // Add the item to the group
            group.events.push(item);

            return acc;
          }, []);

          this.recognizedEvents = groupedData;
        }
      })
      .catch((err) => {
        console.log(err)
      });
    },

    getUnknownEntries() {
      axios({
        method: "POST",
        url: globals.APIURL + "entry/grouped-entries-visionid",
        data: {
          start_date: /* globals.COdate().substring(0, 10) +  */"2024-08-01T00:00:00.000Z",
          finish_date: /* globals.COdate().substring(0, 10) +  */"2024-08-07T23:59:59.000Z",
          limit: 20000,
          type: "unknown",
          ids: auth.getGZ().zones
        },
      })
      .then((res) => {
        if (res.data && res.data.length > 0 && res.data != 'No documents were found') {
          const groupedData = res.data.reduce((acc, item) => {
            const personId = item.person_id;

            // Find the group for the current person_id or create a new one
            let group = acc.find(group => group.person_id === personId);
            if (!group) {
              group = { person_id: personId, events: [] };
              acc.push(group);
            }

            // Add the item to the group
            group.events.push(item);

            return acc;
          }, []);

          this.notRecognizedEvents = groupedData;
        }
      })
      .catch((err) => {
        console.log(err)
      });
    },

    /* getDevices() {
      axios
        .post(globals.APIURL + "device/get-devices-grouped", {
          ids: auth.getGZ().zones
        })
        .then(data =>{ 
          if (data.data.length > 0) {
            this.devices = data.data
            this.getRegistry();
            this.getEntries();
            this.getUnknownEntries();
          } else {
            //this.getRegistry();
            this.getEntries();
            this.getUnknownEntries();
          }
        })
        .catch(err => {
          //this.getRegistry();
          this.getEntries();
          this.getUnknownEntries();
          console.log(err);
        })
    }, */

    getSedes() {
      this.sedes = []
      axios
        .post(globals.APIURL + "org/fo", {
          company_id: auth.getUserEnterpriseID(),
        })
        .then((res) => {
          if (res.data && res.data.Campus.length > 0) {
            this.sedes = res.data.Campus;
            console.log(this.sedes)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getDevices() {
      axios({
        method: "POST",
        baseURL: globals.APIURL + "device/get-devices",
        data: {
          company_id: auth.getUserEnterpriseID(),
          type: "visionid"
        },
      }).then((res) => {
        if (res.data.length > 0) {
          this.getCameras(res.data.map(obj => obj.mac))
          this.getRegistry();
          this.getEntries();
          this.getUnknownEntries();
        }
      }).catch((err) => {
        console.log(err)
      });
    },

    getCameras(devices) {
      axios({
        method: "POST",
        baseURL: globals.APIURL + "device/get-slave-cameras",
        data: {
          devices: devices,
        },
      }).then((res) => {
        if (res.data.length > 0) {
          this.camerasList = res.data
        }
      }).catch((err) => {
        console.log(err)
      });
    },

    checkStreamingConnection() {
      this.streamingUp = true
     /*  this.loadingStreaming = true;
      axios({
        method: "GET",
        baseURL: "http://10.10.1.164:5000/video_feed",
        timeout: 6000,
      }).then((data) => {
        this.loadingStreaming = false;
        console.log("GOO!", data);
      }).catch(() => {
        this.loadingStreaming = false;
        this.streamingUp = false;
      }); */
    },

    getEvery() {
      setInterval(() => {
        this.getEntries();
        this.getUnknownEntries();
      }, 10000);
    }
  },
  mounted() {
    this.getSedes()
    this.getPremissionGroups()
    this.getDevices()
    this.checkStreamingConnection();
    //this.getEvery()
  },
  beforeDestroy() {
  },
};
</script>

<style>
.h0 {
  height: 100%;
}

.border-granted {
  border-bottom: 4px solid green !important;
}

.border-bl {
  border-bottom: 4px solid black !important;
}

.border-denied {
  border-bottom: 4px solid orange !important;
}
</style>