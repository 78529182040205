<template>
  <v-container fluid style="height: 100%;" class="pb-0">
    <div class="text-center" v-if="dialogCreate == true">
      <v-dialog v-model="dialogCreate" persistent width="50%">
        <v-card>
          <v-card-title class="
                black--text
                headline
                grey
                lighten-2
                d-flex
                justify-center
              ">
            Crear Cámara
          </v-card-title>
          <v-card-text>
            <v-row class="text-center">
              <v-col cols="12">
                <v-select
                  v-model="slave.campus"
                  :items="campus"
                  item-text="name"
                  item-value="uuid"
                  return-object
                  dense
                  label="Punto / Posición"
                  outlined
                  dark
                  color="secondary"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="slave.name" label="Nombre Cámara" dense outlined dark
                  color="secondary" hide-details class="pb-5 border-radius"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="slave.place" label="Nombre Estrategico Lugar" dense outlined dark
                  color="secondary" hide-details class="pb-5 border-radius"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="slave.rtsp" label="RTSP" dense outlined dark
                  color="secondary" hide-details class="pb-5 border-radius"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" class="d-flex justify-center align-center">
                <v-btn block color="secondary" @click="createSlave(slave)">
                  Guardar cambios
                </v-btn>
              </v-col>
              <v-col cols="12" md="6" class="d-flex justify-center align-center">
                <v-btn block color="error" @click="cleanCreation(); dialogCreate = !dialogCreate">
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <div class="text-center" v-if="dialogEdit == true">
      <v-dialog v-model="dialogEdit" persistent width="50%">
        <v-card>
          <v-card-title class="
                black--text
                headline
                grey
                lighten-2
                d-flex
                justify-center
              ">
            Editar Cámara
          </v-card-title>
          <v-card-text>
            <v-row class="text-center">
              <!-- <v-col cols="12">
                <v-select
                  v-model="selectedSlave.campus"
                  :items="campus"
                  item-text="name"
                  item-value="uuid"
                  return-object
                  dense
                  label="Punto / Posición"
                  outlined
                  dark
                  color="secondary"
                  hide-details
                ></v-select>
              </v-col> -->
              <v-col cols="12" md="6">
                <v-text-field v-model="selectedSlave.name" label="Nombre" dense outlined dark
                  color="secondary" hide-details class="pb-5 border-radius"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="selectedSlave.place" label="Lugar" dense outlined dark
                  color="secondary" hide-details class="pb-5 border-radius"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="selectedSlave.communication" label="RTSP" dense outlined dark
                  color="secondary" hide-details class="pb-5 border-radius"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" class="d-flex justify-center align-center">
                <v-btn block color="secondary" @click="saveSlaveChanges(selectedSlave)">
                  Guardar cambios
                </v-btn>
              </v-col>
              <v-col cols="12" md="6" class="d-flex justify-center align-center">
                <v-btn block color="error" @click="selectedSlave = null; dialogEdit = !dialogEdit">
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <div class="text-center" v-if="dialogDelete == true">
      <v-dialog v-model="dialogDelete" persistent width="30%">
        <v-card>
          <v-card-text class="pb-0">
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                ¿Esta seguro que desea eliminar la cámara?
              </v-col>
              <v-col cols="12" md="6" class="d-flex justify-end align-center">
                <v-btn color="secondary" small @click="deleteSlave(selectedSlave)">
                  Si, proceder
                </v-btn>
              </v-col>
              <v-col cols="12" md="6" class="d-flex justify-start align-center">
                <v-btn color="red" small @click="dialogDelete = !dialogDelete">
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <v-row>
      <v-col cols="4">
        <p class="text-h5">Cámaras</p>
      </v-col>
      <v-col cols="8" class="text-right">
        <v-btn class="text-right" color="secondary" small @click="dialogCreate = !dialogCreate">
          Crear Cámara
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-text-field v-model="search" prepend-inner-icon="mdi-magnify" label="Filtrar" single-line dense outlined dark
          color="secondary" hide-details class="pb-5 border-radius"></v-text-field>
        <v-layout child-flex>
          <v-data-table :headers="headers" :items="cameras" :search="search" :items-per-page="5" :header-props="{
            mobile: true,
          }" :footer-props="{
                    'items-per-page-options': [30],
                  }" class="elevation-6 dtTable1 border-radius" fixed-header
            no-results-text="No coinciden elementos con la palabra clave ingresada.">
            <template v-slot:[`item.status`]="{ item }">
              <v-chip small :color="item.statua === true ? 'green darken-2' : 'error darken-2'" text-color="white"
                class="darken-1 px-1">
                <span class="ml-3">2024-09-17 17:56:34 </span>
                <v-icon color="white" size="20" class="text-center">
                  {{ item.status === true ? 'mdi-access-point' : 'mdi-access-point-off' }}
                </v-icon>
              </v-chip>
            </template>
            <template v-slot:[`item.options`]="{ item }">
              <div class="d-flex justify-center">
                <v-icon class="iconOptions blueV mr-1" title="Ver img de la cámara">
                  fal fa-eye
                </v-icon>
                <v-icon class="iconOptions blue mx-1" title="Editar cámara"
                  @click="selectedSlave = item; dialogEdit = !dialogEdit">
                  fal fa-edit
                </v-icon>
                <v-icon class="iconOptions red ml-1" title="Eliminar cámara"
                  @click="selectedSlave = item; dialogDelete = !dialogDelete">
                  fal fa-trash-alt
                </v-icon>
              </div>
            </template>
          </v-data-table>
        </v-layout>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" timeout="6000" :color="snackbarColor">
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
  </v-container>
</template>

<script>
import globals from "../../globals";
import axios from "axios";
import auth from "../../services/auth";
//import backlog from "../services/logs";

export default {
  name: "Configuration",
  components: {},
  data() {
    return {
      snackbar: false,
      snackbarColor: "",
      snackbarText: "",
      search: "",
      headers: [
        { text: "Estado", align: "center", value: "status", sortable: false },
        { text: "Nombre", align: "center", value: "name", sortable: true },
        { text: "Lugar", align: "center", value: "place", sortable: false },
        { text: "Opciones", align: "center", value: "options", sortable: false },
      ],
      campus: [],
      masters: [],
      cameras: [],
      slave: {
        name: "",
        place: "",
        rtsp: "",
        campus: null
      },
      dialogCreate: false,
      dialogEdit: false,
      dialogDelete: false,
      selectedSlave: null
    };
  },
  methods: {
    getCampus() {
      axios({
        method: "POST",
        baseURL: globals.APIURL + "campus/facb",
        data: {
          company_id: auth.getUserEnterpriseID()
        },
      }).then((res) => {
        if (res.data && res.data.length > 0) {
          //console.log("Lista de puntos: ", res)
          this.campus = res.data;
        }
      }).catch((err) => {
        console.log(err)
      });
    },

    getDevices() {
      axios({
        method: "POST",
        baseURL: globals.APIURL + "device/get-devices",
        data: {
          company_id: auth.getUserEnterpriseID(),
          type: "visionid"
        },
      }).then((res) => {
        if (res.data.length > 0) {
          //console.log("LISTA DE MAESTROS: ", res.data)
          this.masters = res.data;
          this.getCameras(res.data.map(obj => obj.mac))
        }
      }).catch((err) => {
        console.log(err)
      });
    },

    getCameras(devices) {
      axios({
        method: "POST",
        baseURL: globals.APIURL + "device/get-slave-cameras",
        data: {
          devices: devices,
        },
      }).then((res) => {
        if (res.data.length > 0) {
          //console.log("LISTA DE ESCLAVOS: ", res.data)
          this.cameras = res.data
        }
      }).catch((err) => {
        console.log(err)
      });
    },

    cleanCreation() {
      this.slave.name = ""
      this.slave.place = ""
      this.slave.rtsp = ""
      this.slave.campus = null
    },

    createSlave(item) {
      axios({
        method: "POST",
        baseURL: globals.APIURL + "device/slave-creation-visionid",
        data: {
          "deviceID": this.masters[0].mac,
          "campusID": item.campus.uuid,
          "rtsp": item.rtsp,
          "name": item.name,
          "place": item.place
        },
      }).then((res) => {
        if (res.status === 200) {
          this.snackbar = true;
          this.snackbarColor = "success";
          this.snackbarText = "Se creo la cámara"
          this.getDevices()
          this.dialogCreate = false
          this.cleanCreation()
        }
      }).catch((err) => {
        console.log(err)
      });
    },

    saveSlaveChanges(item) {
      axios({
        method: "PUT",
        baseURL: globals.APIURL + "device/slave-update-visionid",
        data: {
          slaveID: item.slaveID,
          deviceID: item.deviceID,
          name: item.name,
          place: item.place,
          rtsp: item.communication,
          updatedAt: globals.COdate(),
          updatedBy: auth.getUsername()
        },
      }).then((res) => {
        if (res.status === 200) {
          this.snackbar = true;
          this.snackbarColor = "success";
          this.snackbarText = "Se actualizó la cámara"
          this.getDevices()
          this.dialogEdit = false
          this.selectedSlave = null
        }
      }).catch((err) => {
        console.log(err)
      });
    },

    deleteSlave(item) {
      axios({
        method: "DELETE",
        baseURL: globals.APIURL + "device/slave-delete-visionid",
        data: {
          slaveID: item.slaveID,
          deviceID: item.deviceID
        },
      }).then((res) => {
        console.log(res)
        if (res.status === 200) {
          this.snackbar = true;
          this.snackbarColor = "error";
          this.snackbarText = "Se eliminó la cámara"
          this.getDevices()
          this.dialogDelete = false
          this.selectedSlave = null
        }
      }).catch((err) => {
        console.log(err)
      });
    }
  },
  mounted() {
    this.getCampus();
    this.getDevices();
  },
  beforeDestroy() {},
};
</script>

<style>
.border-radius {
  border-radius: 10px
}

.dtTable1 {
  background-color: #31394c !important;
  color: rgb(255 255 255 / 87%) !important;
}

.dtTable1 tbody tr:nth-child(odd) {
  background-color: #212734 !important;
}

.dtTable1>.v-data-table__wrapper>table>thead>tr>th {
  color: #60A5FA !important;
  font-size: 1rem !important;
  background-color: transparent !important;
}

.dtTable1 tbody .v-data-table__empty-wrapper {
  color: #fff !important;
}

.dtTable1 table thead tr th {
  background: #ffffff14 !important;
}

.dtTable1 table tbody tr td {
  font-size: 1rem !important;
  height: 50px !important;
}

.dtTable1 .v-data-footer i {
  color: #fff !important;
}

.dtTable1 tbody tr.selectRowTable,
.selectRowTable {
  background-color: #004aad69 !important;
}

.dtTable1 tbody .iconOptions {
  background: #fff;
  color: #fff;
  width: 35px;
  height: 35px;
  border-radius: 50px;
  text-align: center;
  display: -ms-grid !important;
  display: grid !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.1rem;
  margin: 0 auto;
}

.dtTable1 tbody .v-icon:hover {
  filter: grayscale(50%);
}

.dtTable1 tbody .v-icon.red {
  background: #F87171 !important;
}

.dtTable1 tbody .v-icon.blue {
  background: #60A5FA !important;
}

.dtTable1 tbody .v-icon.blueV {
  background: #004AAD !important;
}

.dtTable1 tbody .v-icon.camStatus {
  background: #004AAD !important;
  outline: 3px solid #a7a7a7;
  border: 4px solid #212734;
  transform: scale(.9);
}

.dtTable1 tbody .v-icon.camStatus.on {
  outline: 3px solid #71f8b9;
}

.dtTable1 tbody .v-icon.camStatus.off {
  outline: 3px solid #f87171;
}

.dtTable1 tbody td p {
  height: 100%;
  display: grid;
  align-items: center;
  background: #9CA3AF;
}

.dtTable1 tbody td p.Pass {
  background: #004AAD;
}

.dtTable1 tbody td p.Secure {
  background: #F87171;
}

.dtTable1 tbody td p.Inspect {
  background: #FBBF24;
}

.dtTable1 tbody td p.Norisk {
  background: #60A5FA;
}

.v-data-table__wrapper {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.v-application--is-ltr .v-data-table--fixed-header .v-data-footer {
  margin-right: 0px;
}
</style>